import {Component, Input} from '@angular/core';
import {parseISO} from 'date-fns';
import {FileResponse} from '../../models/file';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmployeeEntityService} from '../../services/employee-entity.service';
import {catchError, Observable, of} from 'rxjs';
import {Employee} from '../../models/employee';
import {FileEntityService} from '../../services/file-entity.service';
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@codeandweb/ngx-translate";

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent {
  @Input() files: Array<FileResponse>;

  constructor(
    private http: HttpClient,
    private fileService: FileEntityService,
    private snackBar: MatSnackBar,
    private employeeService: EmployeeEntityService,
    private translate: TranslateService
  ) {
  }

  deleteFile(id: string) {
    this.fileService.delete(id, {isOptimistic: false}).subscribe({
      next: () => {
        this.files = this.files.filter((file) => file.id !== id);
        this.snackBar.open(this.translate.instant('Datei wurde gelöscht'), 'OK', {duration: 3000});
      },
      error: () => {
        this.snackBar.open(this.translate.instant('Datei konnte nicht gelöscht werden'), 'OK', {
          duration: 3000,
        });
      },
    });
  }

  getCreatedAt(file: FileResponse): Date | undefined {
    return file.createdAt ? parseISO(file.createdAt) : undefined;
  }

  downloadFile(presignedDownloadUrl: string) {
    window.open(
      presignedDownloadUrl,
      '_blank'
    );
  }

  getEmployee(uploadedByEmployeeId: string): Observable<Employee | undefined> {
    return this.employeeService.get(uploadedByEmployeeId);
  }

  getThumbnail(presignedThumbnailUrl: string): Observable<string | null> {
    return this.http.get(presignedThumbnailUrl, {responseType: 'blob'}).pipe(
      map((blob) => URL.createObjectURL(blob)),
      catchError(() => of(null)) // If there's no thumbnail, return null
    );
  }

}
