import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from '../services/session.service';
import {Customer} from '../models/customer';
import {CustomerEntityService} from '../services/customer-entity.service';
import {format} from 'date-fns';
import {MatDialog} from '@angular/material/dialog';
import {defaultDialogConfig} from '../dialog-util';
import {
  CustomerEditComponent
} from '../../customer-appointment-management/components/customers/customer-edit/customer-edit.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  customers$: Observable<Customer[]>;
  filteredCustomers$: Observable<Customer[]>;
  viewedBirthdays: { date: string; customers: string[] };

  constructor(
    private customerEntityService: CustomerEntityService,
    public sessionService: SessionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const today = format(new Date(), 'yyyy-MM-dd');
    const todaysMonthAndDay = today.substring(5);

    this.viewedBirthdays = localStorage.getItem('viewedBirthdays')
      ? JSON.parse(localStorage.getItem('viewedBirthdays') || '')
      : {};
    if (this.viewedBirthdays.date !== today) {
      this.viewedBirthdays = { date: today, customers: [] };
    }

    this.customers$ = this.customerEntityService.entities$.pipe(
      map((customers) => {
        return customers.filter(
          (it) => !it.deleted && it.birthday?.substring(5) === todaysMonthAndDay
        );
      })
    );

    this.filteredCustomers$ = this.filterCustomers();
  }

  setBirthdayViewed(customer: Customer) {
    this.viewedBirthdays = {
      date: this.viewedBirthdays.date,
      customers: [...this.viewedBirthdays.customers, customer.id],
    };

    this.filteredCustomers$ = this.filterCustomers();

    localStorage.setItem(
      'viewedBirthdays',
      JSON.stringify(this.viewedBirthdays)
    );
  }

  wasBirthdayViewed(customer: Customer) {
    return this.viewedBirthdays.customers.includes(customer.id);
  }

  filterCustomers() {
    return this.customers$.pipe(
      map((customers) => {
        return customers.filter((it) => {
          return !this.wasBirthdayViewed(it);
        });
      })
    );
  }

  editCustomer(customer: Customer) {
    const dialogConfig = defaultDialogConfig();

    dialogConfig.data = {
      customer: customer,
    };

    this.dialog.open(CustomerEditComponent, dialogConfig);
  }
}
