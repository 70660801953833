import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@codeandweb/ngx-translate";

@Component({
  selector: 'dialog-content-example-dialog',
  template: `
    <h1 mat-dialog-title>{{header}}</h1>

    <mat-dialog-content class="mat-typography" *ngFor="let paragraph of text">
      <p>{{paragraph}}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Abbrechen</button>
      <button mat-button color="warn" [mat-dialog-close]="true">{{confirmButtonText}}</button>
    </mat-dialog-actions>
  `
})
export class GenericConfirmDialogComponent {

  header?: string;
  text?: string[];
  confirmButtonText?: string;

  constructor(
    private dialogRef: MatDialogRef<GenericConfirmDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) private data?: { header?: string, confirmButtonText?: string, text?: string[] }
  ) {
    this.header = data?.header || translate.instant('Bestätigung erforderlich');
    this.text = data?.text || [translate.instant('Objekt löschen? Diese Aktion kann nicht rückgängig gemacht werden.')];
    this.confirmButtonText = data?.confirmButtonText || translate.instant('Entfernen');
  }

}
