import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {first, Observable, tap} from 'rxjs';
import {filter} from "rxjs/operators";
import {CustomerEntityService} from "./customer-entity.service";

@Injectable()
export class CustomerResolver {

  constructor(
    private customerEntityService: CustomerEntityService,
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.customerEntityService.loaded$
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this.customerEntityService.getAll();
          }
        }),
        filter(loaded => !!loaded),
        first()
      );
  }
}
