import {Component, Input} from '@angular/core';

export type BusyState = { busy: boolean, text?: string };

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss']
})
export class BusyIndicatorComponent {

  @Input()
  state: BusyState | null = {busy: false};

  constructor() {
  }

}
