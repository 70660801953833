import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Observable, startWith} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {MatDialog} from "@angular/material/dialog";
import {SessionService} from "../services/session.service";
import {Router, RouterModule} from "@angular/router";
import {logout} from "../store/actions";
import {select, Store} from "@ngrx/store";
import {SharedModuleState} from "../store/reducers";
import {cognitoUserAttributesSelector, currentUserSelector} from "../store/selectors";
import {Employee} from "../models/employee";

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input()
  navListTemplate: TemplateRef<any>;

  isScreenLessThan600Px$: Observable<boolean> = this.breakpointObserver.observe('(min-width: 600px)')
    .pipe(
      map((result: BreakpointState) => !result.matches),
      startWith(window.innerWidth >= 600),
      shareReplay()
    );
  currentUser$: Observable<Employee | undefined>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    public sessionService: SessionService,
    private router: Router,
    private store: Store<{ shared: SharedModuleState }>
  ) {

  }

  ngOnInit(): void {
    this.store.pipe(
      select(cognitoUserAttributesSelector)
    ).subscribe()
    this.currentUser$ = this.store.pipe(
      select(currentUserSelector)
    )
  }

  signOut() {
    const dialogRef = this.dialog.open(SignoutConfirmDialogComponent);
    dialogRef.afterClosed().subscribe((signoutConfirmed: boolean) => {
      if (signoutConfirmed) {
        this.store.dispatch(logout())
        this.router.navigate(['/public/login'], {
          queryParams: {
            signOut: true
          }
        });
      }
    });
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `
    <h1 mat-dialog-title>Nutzer Abmelden</h1>

    <mat-dialog-content class="mat-typography">
      <p>Wollen Sie sich wirklich abmelden?</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Abbrechen</button>
      <button mat-button color="warn" [mat-dialog-close]="true">Abmelden</button>
    </mat-dialog-actions>
  `
})
export class SignoutConfirmDialogComponent {
}
