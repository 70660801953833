import {getShortNameForWeekday, WeekDay} from "src/app/shared/work-schedule-utils";
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'weekDayShortName'
})
export class WeekdayShortNamePipe implements PipeTransform {

  transform(weekDay: WeekDay): string {
    return getShortNameForWeekday(weekDay);
  }

}
