import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FileUploadConfirmDialogComponent} from "./file-upload-confirm-dialog/file-upload-confirm-dialog.component";
import {defaultDialogConfig} from "../../dialog-util";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileResponse} from "@app/shared/models/file";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
  }

  @Input() metadata: Record<string, string>
  @Input() customTemplate?: TemplateRef<any>;
  @Output() fileUploaded = new EventEmitter<FileResponse>();

  file?: File;

  onFileSelected(event: any) {
    this.file = event.target.files[0];

    if (this.file) {
      if (this.file.size > 100 * 1024 * 1024) {
        this.snackBar.open('Datei darf höchstens 10 MB groß sein.', 'OK');
        this.file = undefined;
        return;
      }

      const dialogConfig = defaultDialogConfig();
      dialogConfig.data = {file: this.file, metadata: this.metadata};

      const dialogRef = this.dialog.open<FileUploadConfirmDialogComponent, any, FileResponse | null>(FileUploadConfirmDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe({
        next: (response) => {
          this.file = undefined;

          if (response) {
            this.fileUploaded.emit(response);
          }
        }
      });


    }
  }

}
