import {Component, Inject} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FileEntityService} from "../../../services/file-entity.service";
import {map} from "rxjs/operators";
import {TranslateService} from "@codeandweb/ngx-translate";

export function s3ObjectKeyValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value as string;

  // Check if the value is not empty and not too long
  if (!value || new TextEncoder().encode(value).length > 1024) {
    return {'s3KeyInvalid': true};
  }

  // Check for forbidden patterns like leading/trailing periods or double periods
  if (value.startsWith('.') || value.endsWith('.') || value.includes('..')) {
    return {'s3KeyInvalid': true};
  }

  return null; // Return null if the value is valid
}


@Component({
  selector: 'app-file-upload-confirm-dialog',
  templateUrl: './file-upload-confirm-dialog.component.html',
  styleUrls: ['./file-upload-confirm-dialog.component.scss']
})
export class FileUploadConfirmDialogComponent {

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public fileService: FileEntityService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<FileUploadConfirmDialogComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: {
      file: File,
      metadata: Record<string, string>
    }
  ) {
    const originalFilename = data.file.name.replace(/\s+/g, '').trim();
    const providedFilename = data.metadata['filename'];

    const extension = originalFilename.substring(originalFilename.lastIndexOf('.'));
    const fileNameWithoutWhitespace = providedFilename ?
      `${providedFilename}${extension}` : originalFilename;

    this.form = this.fb.group({
      fileName: [fileNameWithoutWhitespace, [Validators.required, s3ObjectKeyValidator]]
    });
  }

  busy$ = this.fileService.loading$.pipe(
    map(loading => ({busy: loading}))
  );

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpload(): void {
    const file: File = this.data.file;
    this.fileService.upload(file, this.form.getRawValue().fileName, this.data.metadata).subscribe({
        next: (response) => {
          this.snackBar.open(this.translate.instant('Datei hochgeladen'), 'OK', {duration: 3000});
          this.dialogRef.close(response);
        },
        error: (error) => {
          if (error.error.status)
            this.snackBar.open(this.translate.instant('Nicht ausreichend Speicherplatz verfügbar'), 'OK', {duration: 3000});
          else
            this.snackBar.open(this.translate.instant('Datei konnte nicht hochgeladen werden'), 'OK', {duration: 3000});
          this.dialogRef.close();
        }
      }
    )

  }
}
