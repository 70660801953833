import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink, RouterOutlet} from "@angular/router";
import {Customer, CustomerNamePipe} from "./models/customer";
import {BackButtonDirective} from "./services/navigation.service";
import {BusyIndicatorComponent} from "./components/busy-indicator/busy-indicator.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
  GenericConfirmDialogComponent
} from "./components/generic-deletion-confirm-dialog/generic-confirm-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {TimePickerComponent} from './components/time-picker/time-picker.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {RequestInterceptor} from "./interceptors/request.interceptor";
import {SideNavComponent, SignoutConfirmDialogComponent} from "./side-nav/side-nav.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatMenuModule} from "@angular/material/menu";
import {ColorBubbleComponent} from './components/color-bubble/color-bubble.component';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {EmployeeEntityService} from "./services/employee-entity.service";
import {EntityDefinitionService} from "@ngrx/data";
import {Employee} from "./models/employee";
import {EmployeeResolver} from "./services/employee-resolver.service";
import {CustomerEntityService} from "./services/customer-entity.service";
import {CustomerResolver} from "./services/customer-resolver.service";
import {StoreModule} from "@ngrx/store";
import {sharedReducer} from "./store/reducers";
import {EffectsModule} from "@ngrx/effects";
import {SharedModuleEffects} from "./store/effects";
import {ThemePickerComponent} from './components/theme-picker/theme-picker.component';
import {PublicHolidaysEntity} from "./models/public-holidays-entity";
import {NotificationComponent} from './notification/notification.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {FileSizePipe} from './pipes/bytes-to-kilobytes.pipe';
import {
  FileUploadConfirmDialogComponent
} from './components/file-upload/file-upload-confirm-dialog/file-upload-confirm-dialog.component';
import {FileListComponent} from './components/file-list/file-list.component';
import {MatExpansionModule} from "@angular/material/expansion";
import {MatListModule} from "@angular/material/list";
import {FileResponse} from "./models/file";
import {AppointmentEntityService} from "../customer-appointment-management/services/appointment-entity.service";
import {DurationDaysPipe, DurationPipe} from "./pipes/duration-pipe.pipe";
import {CentsToEurPipe} from "./pipes/cents-to-eur.pipe";
import {TranslateDayPipe} from "./pipes/translate-day.pipe";
import {WeekdayShortNamePipe} from "./pipes/weekday.pipe";
import {PaginatorIntl} from "./providers/Paginator";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {HighlightPipe} from "./pipes/highlight.pipe";
import {BookableTimeslot} from "@app/shared/services/bookable-timeslot-entity.service";
import {CustomDataFieldEntityService} from "@app/shared/services/custom-data-field-entity.service";
import {CustomDataFieldEntity} from "@app/shared/models/custom-data-field";
import {ShiftEntity} from "@app/shared/models/shift";
import {TranslatePipe} from "@codeandweb/ngx-translate";


@NgModule({
  declarations: [
    NotFoundComponent,
    BusyIndicatorComponent,
    CustomerNamePipe,
    WeekdayShortNamePipe,
    BackButtonDirective,
    GenericConfirmDialogComponent,
    TimePickerComponent,
    SideNavComponent,
    NotificationComponent,
    SignoutConfirmDialogComponent,
    ColorBubbleComponent,
    DurationPipe,
    DurationDaysPipe,
    CentsToEurPipe,
    ThemePickerComponent,
    FileUploadComponent,
    FileSizePipe,
    FileUploadConfirmDialogComponent,
    FileListComponent,
    TranslateDayPipe,
    HighlightPipe,
  ],
  exports: [
    CustomerNamePipe,
    WeekdayShortNamePipe,
    BackButtonDirective,
    BusyIndicatorComponent,
    ColorBubbleComponent,
    TimePickerComponent,
    SideNavComponent,
    NotificationComponent,
    DurationPipe,
    DurationDaysPipe,
    CentsToEurPipe,
    ThemePickerComponent,
    FileUploadComponent,
    FileSizePipe,
    FileListComponent,
    TranslateDayPipe,
    HighlightPipe
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    RouterLink,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatSnackBarModule,
    RouterOutlet,
    StoreModule.forFeature('shared', sharedReducer),
    EffectsModule.forFeature([SharedModuleEffects]),
    MatTooltipModule,
    MatExpansionModule,
    MatListModule,
    TranslatePipe
  ], providers: [
    CustomerEntityService,
    CustomerResolver,
    EmployeeEntityService,
    CustomDataFieldEntityService,
    EmployeeResolver,
    AppointmentEntityService,
    {provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true},
    {provide: MatPaginatorIntl, useClass: PaginatorIntl},
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class SharedModule {

  constructor(
    entityDefinitionService: EntityDefinitionService
  ) {
    entityDefinitionService.registerMetadataMap({
      Employees: {
        selectId: (employee: Employee) => employee.id,
      },
      BookableTimeslots: {
        selectId: (bookableTimeslot: BookableTimeslot) => bookableTimeslot.employeeId + bookableTimeslot.start,
      },
      Shifts: {
        selectId: (shift: ShiftEntity) => shift.id,
      },
      Files: {
        selectId: (file: FileResponse) => file.id,
      },
      Customers: {
        selectId: (customer: Customer) => customer.id,
      },
      PublicHolidays: {
        selectId: (publicHolidaysEntity: PublicHolidaysEntity) => publicHolidaysEntity.id,
      },
      CustomDataFields: {
        selectId: (dataFieldEntity: CustomDataFieldEntity) => dataFieldEntity.entityType + '_' + dataFieldEntity.key,
      }
    })
  }

}
