import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {first, Observable, tap} from 'rxjs';
import {filter} from "rxjs/operators";
import {EmployeeEntityService} from "./employee-entity.service";

/**
 * Resolvers delay the navigation to a route until certain data is loaded.
 *
 * Add this resolver to a route in routing module.
 * Angular will call the resolve() method before loading that route.
 *
 * The resolve() method of this resolver checks if employees have ever
 * been loaded from the backend.
 *
 * If yes, returns 'true' and allows to navigate to the requested route.
 *
 * If not, trigger request to backend via getAll() call. The filter() will prevent
 * the Observable to return a 'false' response, so navigation does not fail.
 * After the backend request returns, the loaded$ observable will emit another value,
 * which is 'true' this time. Then the navigation will continue.
 */
@Injectable()
export class EmployeeResolver {

  constructor(
    private employeeEntityService: EmployeeEntityService,
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.employeeEntityService.loaded$
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this.employeeEntityService.getAll();
          }
        }),
        filter(loaded => !!loaded),
        first()
      );
  }
}
