import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from "rxjs";
import {Color, UserPermissions} from "../models/employee";
import {Hub} from "aws-amplify/utils";
import {BusyState} from "../components/busy-indicator/busy-indicator.component";
import {map} from "rxjs/operators";
import {select, Store} from "@ngrx/store";
import {tenantFeatureConfigSelector} from "../store/selectors";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public readonly currentUser$: Subject<CalendallUser> = new BehaviorSubject<CalendallUser>(NULL_USER);
  public readonly busy$: Subject<BusyState> = new BehaviorSubject<BusyState>({busy: false});

  public readonly tenantFeatureConfig$ = this.store.pipe(
    select(tenantFeatureConfigSelector)
  );

  employeeManagementPermitted$: Observable<boolean> = this.currentUser$.pipe(
    map(currentUser => {
      return currentUser.isTenantAdmin || currentUser.permissions?.employeeManagement
    })
  );
  appointmentTemplateManagementPermitted$: Observable<boolean> = this.currentUser$.pipe(
    map(currentUser => {
      return currentUser.isTenantAdmin || currentUser.permissions?.appointmentTemplateManagement
    })
  );
  onlineBookingManagementPermitted$: Observable<boolean> = combineLatest([this.currentUser$, this.tenantFeatureConfig$]).pipe(
    map(([currentUser, featureConfig]) => {
      const tenantFeatureEnabled = featureConfig.onlineBooking ?? false;
      const userPermitted = currentUser.isTenantAdmin || currentUser.permissions?.onlineBookingManagement;
      return userPermitted && tenantFeatureEnabled
    })
  );

  constructor(private store: Store) {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signedOut') {
        this.resetUserData();
      }
    });
  }

  public setCurrentUser(user: CalendallUser) {
    this.currentUser$.next(user);
  }

  public resetUserData() {
    this.currentUser$.next(NULL_USER);
  }
}

export interface CalendallUser {
  username: string,
  internalUsername: string,
  employeeId: string,
  color?: Color,
  tenantId: string,
  permissions: UserPermissions
  isTenantAdmin: boolean
}

const NO_PERMISSIONS: UserPermissions = {
  appointmentsOfOtherEmployees: false,
  appointmentTemplateManagement: false,
  employeeManagement: false,
  analytics: false,
  tenantOwner: false,
  tenantManagement: false,
  onlineBookingManagement: false
};
const NULL_USER: CalendallUser = {
  employeeId: "",
  tenantId: "",
  permissions: NO_PERMISSIONS,
  username: "",
  internalUsername: '',
  isTenantAdmin: false
};
