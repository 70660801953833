import {Injectable} from "@angular/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {Subject} from "rxjs";
import {TranslateService} from "@codeandweb/ngx-translate";

@Injectable()
export class PaginatorIntl implements MatPaginatorIntl {

  constructor(
    private translate: TranslateService,
  ) {
  }

  changes = new Subject<void>();

  firstPageLabel = this.translate.instant(`Erste Seite`);
  itemsPerPageLabel = this.translate.instant(`Zeilen pro Seite:`);
  lastPageLabel = this.translate.instant(`Letzte Seite`);

  nextPageLabel = this.translate.instant('Nächste Seite');
  previousPageLabel = this.translate.instant('Vorherige Seite');

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.instant('PAGINATOR.PAGE_X_OF_Y', {current: 1, total: 1});
    }
    const amountPages = Math.ceil(length / pageSize);
    return this.translate.instant('PAGINATOR.PAGE_X_OF_Y', {current: page + 1, total: amountPages});
  }
}
