<h2 mat-dialog-title translate>Datei hochladen</h2>
<mat-dialog-content class="container">
    <form [formGroup]="form">
        <div class="row">
            <mat-form-field class="col-12 col-md-6">
              <mat-label translate="">Dateiname</mat-label>
                <input matInput placeholder="File Name" formControlName="fileName">
            </mat-form-field>
        </div>
      <p translate>Hinweis: Nach erfolgreichem Hochladen einer Bilddatei generieren wir ein Vorschaubild (Thumbnail). Es
        kann
        einige Minuten dauern, bis das Vorschaubild verfügbar ist.</p>
    </form>
    <app-busy-indicator [state]="busy$ | async"></app-busy-indicator>
</mat-dialog-content>
<mat-dialog-actions class="button-bar">
  <button mat-button (click)="onCancel()" translate>Abbrechen</button>
  <button mat-flat-button color="primary" (click)="onUpload()" [disabled]="!form.valid" translate>Hochladen</button>
</mat-dialog-actions>
