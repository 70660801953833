import {PostalAddress} from "./postal-address";
import {Pipe, PipeTransform} from "@angular/core";

export interface Customer {
  id: string,
  firstName?: string,
  lastName?: string,
  salutation?: string,
  phoneNumber?: string,
  emailAddress?: string,
  reminderSMS?: boolean
  reminderEmail?: boolean
  postalAddress?: PostalAddress,
  companyName?: string,
  notes?: string,
  birthday?: string,
  createdAt: string,
  deleted?: string,
  customDataFields?: Record<string, string>
}

export interface CustomerCreateRequest {
  firstName?: string,
  lastName?: string,
  salutation?: string,
  phoneNumber?: string,
  emailAddress?: string,
  reminderSMS?: boolean
  reminderEmail?: boolean
  postalAddress?: PostalAddress,
  companyName?: string,
  notes?: string,
  birthday?: string,
  customDataFields?: Record<string, string>,
}

export function transformCustomerName(customer?: Customer | CustomerCreateRequest) {
  if (!customer) return '';

  const firstName = customer.firstName;
  const lastName = customer.lastName;
  const companyName = customer.companyName;

  let name = "";
  if (lastName && firstName) {
    name = `${firstName} ${lastName}`
  } else if (lastName) {
    name = lastName;
  } else if (firstName) {
    name = firstName;
  }

  if (companyName) {
    name = name ? name + ` | ${companyName}` : name + companyName
  }

  if (!name) {
    name = customer.notes?.substring(0, Math.min(20, customer.notes?.length)) || '';
  }

  return name;
}

@Pipe({name: 'customerName'})
export class CustomerNamePipe implements PipeTransform {
  transform(customer?: Customer | CustomerCreateRequest, format?: string) {
    if (!customer) return '-';

    let customerName = transformCustomerName(customer);

    // Check if the format argument is 'long' and append the phoneNumber if it exists
    if (format === 'phone' && customer.phoneNumber) {
      customerName += ` (${customer.phoneNumber})`;
    }

    return customerName;
  }
}
