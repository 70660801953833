import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@codeandweb/ngx-translate";

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {
  }

  transform(minutes: number | undefined): string {
    if (minutes === undefined) return '';

    const minutesInADay = 24 * 60;

    const fullDays = Math.floor(minutes / minutesInADay);
    const daysText = fullDays === 0 ? `` : fullDays === 1 ? `${fullDays} ${this.translate.instant('Tag')}` : `${fullDays} ${this.translate.instant('Tage')}`;

    const minutesWithoutFullDays = minutes - (fullDays * minutesInADay);
    const fullHours = Math.floor(minutesWithoutFullDays / 60);
    const hoursText = fullHours === 0 ? `` : fullHours === 1 ? `${fullHours} ${this.translate.instant('Stunde')}` : `${fullHours} ${this.translate.instant('Stunden')}`;

    const minutesWithoutFullHours = minutesWithoutFullDays - (fullHours * 60);
    const minutesText = minutesWithoutFullHours === 0 ? `` : `${minutesWithoutFullHours} ${this.translate.instant('Minuten')}`;
    return `${daysText} ${hoursText} ${minutesText}`;
  }

}

@Pipe({
  name: 'durationDays'
})
export class DurationDaysPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {
  }

  transform(days: number): string {

    const fullYears = Math.floor(days / 365);
    const yearsText = fullYears === 0 ? `` : fullYears === 1 ? `${fullYears} ${this.translate.instant('Jahr')}` : `${fullYears} ${this.translate.instant('Jahre')}`;

    const daysWithoutFullYears = days - (fullYears * 365);
    const fullMonths = Math.floor(daysWithoutFullYears / 30);
    const monthsText = fullMonths === 0 ? `` : fullMonths === 1 ? `${fullMonths} ${this.translate.instant('Monat')}` : `${fullMonths} ${this.translate.instant('Monate')}`;

    const daysWithoutFullMonths = daysWithoutFullYears - (fullMonths * 30);
    const daysText = daysWithoutFullMonths === 0 ? `` : `${daysWithoutFullMonths} ${this.translate.instant('Tage')}`;
    return `${yearsText} ${monthsText} ${daysText}`;
  }

}
