import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  // if value contains searchString, wrap it in <span class="highlight">.
  // style from styles.css for .highlight class will be applied
  transform(value?: string, searchString?: string): SafeHtml | undefined {
    if (!searchString || !value) {
      return value;
    }
    // Escape any regex special characters in the search string
    const re = new RegExp(searchString.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi');
    const match = value.match(re);

    if (!match) {
      return value;
    }

    const replacedValue = value.replace(re, `<span class="highlight">$&</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
