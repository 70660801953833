import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-color-bubble',
  templateUrl: './color-bubble.component.html',
  styleUrls: ['./color-bubble.component.scss']
})
export class ColorBubbleComponent {
  @Input() color?: string
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
