import {addDays, addMonths, addWeeks} from "date-fns";

export enum CalendallView {
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

export const getCalendallViewAddFunction = (granularity: CalendallView) => {
  switch (granularity) {
    case "month":
      return addMonths;
    case "week":
      return addWeeks;
    case "day":
      return addDays;
    default:
      throw new Error('Not implemented');
  }
}
