import { Component } from '@angular/core';
import { ThemeService } from '../../services/theme.service';

type ThemeOption = {
  label: string;
  value: string;
};

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss'],
})
export class ThemePickerComponent {
  themes: Array<ThemeOption> = [
    {
      label: 'Light',
      value: 'light',
    },
    {
      label: 'Dark',
      value: 'dark',
    },
    {
      label: 'System',
      value: 'system',
    },
  ];

  constructor(public themeService: ThemeService) {}

  setTheme(theme: ThemeOption): void {
    this.themeService.setTheme(theme.value);
  }

  getTheme() {
    const theme = this.themeService.getTheme();
    return this.themes.find((it) => it.value === theme);
  }
}
