const ComponentClassName = {
  Accordion: 'amplify-accordion',
  AccordionItem: 'amplify-accordion__item',
  AccordionItemTrigger: 'amplify-accordion__item__trigger',
  AccordionItemContent: 'amplify-accordion__item__content',
  AccordionItemIcon: 'amplify-accordion__item__icon',
  Alert: 'amplify-alert',
  AlertIcon: 'amplify-alert__icon',
  AlertHeading: 'amplify-alert__heading',
  AlertBody: 'amplify-alert__body',
  AlertDismiss: 'amplify-alert__dismiss',
  Autocomplete: 'amplify-autocomplete',
  AutocompleteMenu: 'amplify-autocomplete__menu',
  AutocompleteMenuEmpty: 'amplify-autocomplete__menu--empty',
  AutocompleteMenuFooter: 'amplify-autocomplete__menu__footer',
  AutocompleteMenuHeader: 'amplify-autocomplete__menu__header',
  AutocompleteMenuLoading: 'amplify-autocomplete__menu--loading',
  AutocompleteMenuOption: 'amplify-autocomplete__menu__option',
  AutocompleteMenuOptions: 'amplify-autocomplete__menu__options',
  Avatar: 'amplify-avatar',
  AvatarIcon: 'amplify-avatar__icon',
  AvatarImage: 'amplify-avatar__image',
  AvatarLoader: 'amplify-avatar__loader',
  AIConversation: 'amplify-ai-conversation',
  AIConversationAttachment: 'amplify-ai-conversation__attachment',
  AIConversationAttachmentList: 'amplify-ai-conversation__attachment__list',
  AIConversationAttachmentImage: 'amplify-ai-conversation__attachment__image',
  AIConversationAttachmentName: 'amplify-ai-conversation__attachment__name',
  AIConversationAttachmentSize: 'amplify-ai-conversation__attachment__size',
  AIConversationAttachmentRemove: 'amplify-ai-conversation__attachment__remove',
  AIConversationForm: 'amplify-ai-conversation__form',
  AIConversationFormAttach: 'amplify-ai-conversation__form__attach',
  AIConversationFormSend: 'amplify-ai-conversation__form__send',
  AIConversationFormField: 'amplify-ai-conversation__form__field',
  AIConversationFormDropzone: 'amplify-ai-conversation__form__dropzone',
  AIConversationMessage: 'amplify-ai-conversation__message',
  AIConversationMessageAvatar: 'amplify-ai-conversation__message__avatar',
  AIConversationMessageSender: 'amplify-ai-conversation__message__sender',
  AIConversationMessageSenderUsername: 'amplify-ai-conversation__message__sender__username',
  AIConversationMessageSenderTimestamp: 'amplify-ai-conversation__message__sender__timestamp',
  AIConversationMessageBody: 'amplify-ai-conversation__message__body',
  AIConversationMessageContent: 'amplify-ai-conversation__message__content',
  AIConversationMessageActions: 'amplify-ai-conversation__message__actions',
  AIConversationMessageList: 'amplify-ai-conversation__message__list',
  AIConversationPrompt: 'amplify-ai-conversation__prompt',
  Badge: 'amplify-badge',
  Breadcrumbs: 'amplify-breadcrumbs',
  BreadcrumbsList: 'amplify-breadcrumbs__list',
  BreadcrumbsItem: 'amplify-breadcrumbs__item',
  BreadcrumbsSeparator: 'amplify-breadcrumbs__separator',
  BreadcrumbsLink: 'amplify-breadcrumbs__link',
  Button: 'amplify-button',
  ButtonGroup: 'amplify-buttongroup',
  ButtonLoaderWrapper: 'amplify-button__loader-wrapper',
  Card: 'amplify-card',
  Checkbox: 'amplify-checkbox',
  CheckboxButton: 'amplify-checkbox__button',
  CheckboxIcon: 'amplify-checkbox__icon',
  CheckboxInput: 'amplify-checkbox__input',
  CheckboxLabel: 'amplify-checkbox__label',
  CheckboxField: 'amplify-checkboxfield',
  Collection: 'amplify-collection',
  CollectionItems: 'amplify-collection-items',
  CollectionSearch: 'amplify-collection-search',
  CollectionPagination: 'amplify-collection-pagination',
  CountryCodeSelect: 'amplify-countrycodeselect',
  DialCodeSelect: 'amplify-dialcodeselect',
  Divider: 'amplify-divider',
  DividerLabel: 'amplify-divider--label',
  DropZone: 'amplify-dropzone',
  Field: 'amplify-field',
  FieldDescription: 'amplify-field__description',
  FieldErrorMessage: 'amplify-field__error-message',
  FieldGroup: 'amplify-field-group',
  FieldGroupControl: 'amplify-field-group__control',
  FieldGroupOuterEnd: 'amplify-field-group__outer-end',
  FieldGroupOuterStart: 'amplify-field-group__outer-start',
  FieldGroupInnerEnd: 'amplify-field-group__inner-end',
  FieldGroupInnerStart: 'amplify-field-group__inner-start',
  FieldGroupIcon: 'amplify-field-group__icon',
  FieldGroupIconButton: 'amplify-field-group__icon-button',
  FieldGroupHasInnerEnd: 'amplify-field-group--has-inner-end',
  FieldGroupHasInnerStart: 'amplify-field-group--has-inner-start',
  FieldShowPassword: 'amplify-field__show-password',
  FieldGroupFieldWrapper: 'amplify-field-group__field-wrapper',
  Fieldset: 'amplify-fieldset',
  FieldsetLegend: 'amplify-fieldset__legend',
  FileUploader: 'amplify-fileuploader',
  FileUploaderDropZone: 'amplify-fileuploader__dropzone',
  FileUploaderDropZoneIcon: 'amplify-fileuploader__dropzone__icon',
  FileUploaderDropZoneText: 'amplify-fileuploader__dropzone__text',
  FileUploaderFilePicker: 'amplify-fileuploader__file__picker',
  FileUploaderFile: 'amplify-fileuploader__file',
  FileUploaderFileWrapper: 'amplify-fileuploader__file__wrapper',
  FileUploaderFileList: 'amplify-fileuploader__file__list',
  FileUploaderFileName: 'amplify-fileuploader__file__name',
  FileUploaderFileSize: 'amplify-fileuploader__file__size',
  FileUploaderFileInfo: 'amplify-fileuploader__file__info',
  FileUploaderFileImage: 'amplify-fileuploader__file__image',
  FileUploaderFileMain: 'amplify-fileuploader__file__main',
  FileUploaderFileStatus: 'amplify-fileuploader__file__status',
  FileUploaderLoader: 'amplify-fileuploader__loader',
  FileUploaderPreviewer: 'amplify-fileuploader__previewer',
  FileUploaderPreviewerText: 'amplify-fileuploader__previewer__text',
  FileUploaderPreviewerActions: 'amplify-fileuploader__previewer__actions',
  FileUploaderPreviewerFooter: 'amplify-fileuploader__previewer__footer',
  Flex: 'amplify-flex',
  Grid: 'amplify-grid',
  Heading: 'amplify-heading',
  HighlightMatch: 'amplify-highlightmatch',
  HighlightMatchHighlighted: 'amplify-highlightmatch__highlighted',
  Icon: 'amplify-icon',
  Image: 'amplify-image',
  Input: 'amplify-input',
  Label: 'amplify-label',
  Link: 'amplify-link',
  Loader: 'amplify-loader',
  LoaderLabel: 'amplify-loader__label',
  MenuContent: 'amplify-menu__content',
  MenuItem: 'amplify-menu__content__item',
  MenuTrigger: 'amplify-menu__trigger',
  MenuWrapper: 'amplify-menu__wrapper',
  Message: 'amplify-message',
  MessageIcon: 'amplify-message__icon',
  MessageHeading: 'amplify-message__heading',
  MessageBody: 'amplify-message__body',
  MessageContent: 'amplify-message__content',
  MessageDismiss: 'amplify-message__dismiss',
  Pagination: 'amplify-pagination',
  PaginationItem: 'amplify-pagination__item',
  PasswordField: 'amplify-passwordfield',
  PhoneNumberField: 'amplify-phonenumberfield',
  Placeholder: 'amplify-placeholder',
  Radio: 'amplify-radio',
  RadioButton: 'amplify-radio__button',
  RadioInput: 'amplify-radio__input',
  RadioLabel: 'amplify-radio__label',
  RadioGroupField: 'amplify-radiogroupfield',
  RadioGroup: 'amplify-radiogroup',
  Rating: 'amplify-rating',
  RatingItem: 'amplify-rating__item',
  RatingIcon: 'amplify-rating__icon',
  RatingLabel: 'amplify-rating__label',
  ScrollView: 'amplify-scrollview',
  SearchField: 'amplify-searchfield',
  SearchFieldClear: 'amplify-searchfield__clear',
  SearchFieldSearch: 'amplify-searchfield__search',
  Select: 'amplify-select',
  SelectField: 'amplify-selectfield',
  SelectWrapper: 'amplify-select__wrapper',
  SelectIcon: 'amplify-select__icon',
  SliderField: 'amplify-sliderfield',
  SliderFieldGroup: 'amplify-sliderfield__group',
  SliderFieldLabel: 'amplify-sliderfield__label',
  SliderFieldRange: 'amplify-sliderfield__range',
  SliderFieldRoot: 'amplify-sliderfield__root',
  SliderFieldThumb: 'amplify-sliderfield__thumb',
  SliderFieldTrack: 'amplify-sliderfield__track',
  StepperField: 'amplify-stepperfield',
  StepperFieldButtonDecrease: 'amplify-stepperfield__button--decrease',
  StepperFieldButtonIncrease: 'amplify-stepperfield__button--increase',
  StepperFieldInput: 'amplify-stepperfield__input',
  StorageImage: 'amplify-storageimage',
  StorageManager: 'amplify-storagemanager',
  StorageManagerDropZone: 'amplify-storagemanager__dropzone',
  StorageManagerDropZoneIcon: 'amplify-storagemanager__dropzone__icon',
  StorageManagerDropZoneText: 'amplify-storagemanager__dropzone__text',
  StorageManagerFilePicker: 'amplify-storagemanager__file__picker',
  StorageManagerFile: 'amplify-storagemanager__file',
  StorageManagerFileWrapper: 'amplify-storagemanager__file__wrapper',
  StorageManagerFileList: 'amplify-storagemanager__file__list',
  StorageManagerFileName: 'amplify-storagemanager__file__name',
  StorageManagerFileSize: 'amplify-storagemanager__file__size',
  StorageManagerFileInfo: 'amplify-storagemanager__file__info',
  StorageManagerFileImage: 'amplify-storagemanager__file__image',
  StorageManagerFileMain: 'amplify-storagemanager__file__main',
  StorageManagerFileStatus: 'amplify-storagemanager__file__status',
  StorageManagerLoader: 'amplify-storagemanager__loader',
  StorageManagerPreviewer: 'amplify-storagemanager__previewer',
  StorageManagerPreviewerText: 'amplify-storagemanager__previewer__text',
  StorageManagerPreviewerActions: 'amplify-storagemanager__previewer__actions',
  StorageManagerPreviewerFooter: 'amplify-storagemanager__previewer__footer',
  SwitchField: 'amplify-switchfield',
  SwitchLabel: 'amplify-switch__label',
  SwitchThumb: 'amplify-switch__thumb',
  SwitchTrack: 'amplify-switch__track',
  SwitchWrapper: 'amplify-switch__wrapper',
  Table: 'amplify-table',
  TableCaption: 'amplify-table__caption',
  TableBody: 'amplify-table__body',
  TableTd: 'amplify-table__td',
  TableTh: 'amplify-table__th',
  TableFoot: 'amplify-table__foot',
  TableHead: 'amplify-table__head',
  TableRow: 'amplify-table__row',
  Tabs: 'amplify-tabs',
  TabsList: 'amplify-tabs__list',
  TabsItem: 'amplify-tabs__item',
  TabsPanel: 'amplify-tabs__panel',
  Text: 'amplify-text',
  Textarea: 'amplify-textarea',
  TextAreaField: 'amplify-textareafield',
  TextField: 'amplify-textfield',
  ToggleButton: 'amplify-togglebutton',
  ToggleButtonGroup: 'amplify-togglebuttongroup',
  VisuallyHidden: 'amplify-visually-hidden'
};
export { ComponentClassName };