import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable, of, startWith, switchMap} from "rxjs";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimePickerComponent
    }
  ]
})
export class TimePickerComponent implements ControlValueAccessor, OnInit, OnChanges {

  filteredTimes$: Observable<string[]>;

  selectedTimeFormControl = new FormControl<string>({value: '', disabled: false});

  @Input() minuteInterval: number;
  @Input() label: string;
  @Input() required: boolean = false;
  @Input() minTime: Observable<string>;
  @Input() disableInputField: boolean = false;

  touched = false;
  disabled = false;

  constructor() {

  }

  ngOnInit(): void {
    const selectableTimes: Array<string> = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += this.minuteInterval) {
        const value = String(i).padStart(2, '0') + `:` + String(j).padStart(2, '0');
        selectableTimes.push(value);
      }
    }

    this.filteredTimes$ = this.selectedTimeFormControl.valueChanges.pipe(
      startWith(''),
      switchMap(it => {

        return of(selectableTimes.filter(time => time.startsWith(it!)))
      })
    );

    this.minTime?.subscribe((minTime: string) => {

      const selectableTimesAfterMinTime = selectableTimes.filter(it => it >= (minTime));

      this.filteredTimes$ = this.selectedTimeFormControl.valueChanges.pipe(
        startWith(''),
        switchMap(it => {

          return of(selectableTimesAfterMinTime.filter(time => time.startsWith(it!)))
        })
      );
    })


  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.disableInputField) {
      this.selectedTimeFormControl.enable()
    } else {
      this.selectedTimeFormControl.disable()
    }
  }

  onChange = (value: string | null) => {
    // this method will be overwritten by registerOnChange
  };

  onTouched = () => {
  };

  registerOnChange(onChange: any) {
    this.onChange = (value) => {
      onChange(value);
    }
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue(selectedTime: string): void {
    this.selectedTimeFormControl.setValue(selectedTime);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  keyUp() {
    this.onChange(this.selectedTimeFormControl.value);
  }
}
