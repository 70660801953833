import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SharedModuleState} from "./reducers";


export const sharedModuleStateSelector = createFeatureSelector<SharedModuleState>('shared');

export const cognitoUserAttributesSelector = createSelector(
  sharedModuleStateSelector,
  (sharedModuleState: SharedModuleState) => sharedModuleState.cognitoUserAttributes
)
export const isLoggedInSelector = createSelector(
  sharedModuleStateSelector,
  (sharedModuleState: SharedModuleState) => !!sharedModuleState.cognitoUserAttributes
)
export const calendarDisplaySettingsSelector = createSelector(
  sharedModuleStateSelector,
  (sharedModuleState: SharedModuleState) => sharedModuleState.calendarDisplaySettings
)

export const tenantFeatureConfigSelector = createSelector(
  sharedModuleStateSelector,
  (sharedModuleState: SharedModuleState) => sharedModuleState.tenantFeatureConfig
)

export const tenantPublicDetailsSelector = createSelector(
  sharedModuleStateSelector,
  (sharedModuleState: SharedModuleState) => sharedModuleState.tenantPublicDetails
)

export const currentUserSelector = createSelector(
  sharedModuleStateSelector,
  (sharedModuleState: SharedModuleState) => sharedModuleState.currentUser
)

