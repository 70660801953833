import {addDays, addMinutes, getDay, startOfWeek} from "date-fns";
import {toRecurrenceRuleString} from "./recurrence-utils";
import {Frequency} from "rrule";

export type WorkingHours = {
  recurrenceRule: string,
  durationInMinutes: number,
  onlineBooking: boolean,
}

export type WeekDay = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type WeeklyWorkSchedule = {
  monday: Array<WorkingHours>,
  tuesday: Array<WorkingHours>,
  wednesday: Array<WorkingHours>,
  thursday: Array<WorkingHours>,
  friday: Array<WorkingHours>,
  saturday: Array<WorkingHours>,
  sunday: Array<WorkingHours>,

  [key: string]: Array<WorkingHours>
}

export function weeklyRecurrence(start: Date, durationInMinutes: number, onlineBooking: boolean = false) {
  const recurrenceRule = toRecurrenceRuleString({
    startDateTime: start,
    recurrenceInterval: 1,
    recurrenceFrequency: Frequency.WEEKLY,
    recurrenceEnd: null
  })!;
  return {
    recurrenceRule,
    durationInMinutes,
    onlineBooking
  };
}

export const defaultWorkSchedule = (start: Date = new Date) => {
  const workdayStart = 8.5;
  const mondayStart = addMinutes(startOfWeek(start, {
    weekStartsOn: 1
  }), workdayStart * 60)

  const workSchedule: WeeklyWorkSchedule = {
    monday: [weeklyRecurrence(mondayStart, 9 * 60)],
    tuesday: [weeklyRecurrence(addDays(mondayStart, 1), 9 * 60)],
    wednesday: [weeklyRecurrence(addDays(mondayStart, 2), 9 * 60)],
    thursday: [weeklyRecurrence(addDays(mondayStart, 3), 9 * 60)],
    friday: [weeklyRecurrence(addDays(mondayStart, 4), 9 * 60)],
    saturday: [],
    sunday: [],
  };
  return workSchedule
}

export function getWeekDayForIndex(weekdayIndex: number) {
  switch (weekdayIndex) {
    case 0:
      return 'sunday';
    case 1:
      return 'monday';
    case 2:
      return 'tuesday';
    case 3:
      return 'wednesday';
    case 4:
      return 'thursday';
    case 5:
      return 'friday';
    case 6:
      return 'saturday';
    default:
      throw new Error('Invalid input');
  }
}

export const getWeekDay = (date: Date): WeekDay => {
  const weekdayIndex = getDay(date);
  return getWeekDayForIndex(weekdayIndex);
}

export function getShortNameForWeekday(key: string) {
  switch (key) {
    case 'monday':
      return 'Mo';
    case 'tuesday':
      return 'Di';
    case 'wednesday':
      return 'Mi';
    case 'thursday':
      return 'Do';
    case 'friday':
      return 'Fr';
    case 'saturday':
      return 'Sa';
    case 'sunday':
      return 'So';
    default:
      return '';
  }
}

export const getIndexForWeekDay = (weekDay: WeekDay, weekStartsAt: number = 0): number => {
  switch (weekDay) {
    case 'sunday':
      return weekStartsAt === 0 ? 0 : 7;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
  }
}
