<mat-accordion>
    <mat-expansion-panel *ngFor="let file of files">
        <mat-expansion-panel-header>
            <mat-panel-title class="mat-mdc-form-field-flex flex-row gap-2">
              <ng-container *ngIf="file.thumbnail as thumbnail; else icon">
                <img *ngIf="thumbnail; else icon" [src]="thumbnail" alt="thumbnail" class="thumbnail-img"/>
              </ng-container>
              <ng-template #icon>
                <mat-icon>attachment</mat-icon>
              </ng-template>
                {{file.s3Key}}
            </mat-panel-title>
            <mat-panel-description>
                {{file.sizeInBytes | fileSize}}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div>
            <div>Hochgeladen am {{getCreatedAt(file) | date: 'short'}}
                von {{(getEmployee(file.uploadedBy) | async)?.alias || "Unbekannt"}}</div>
            <div class="d-flex flex-row gap-2 mt-2">
              <button mat-stroked-button (click)="downloadFile(file.presignedDownloadUrl)">Öffnen</button>
                <button mat-stroked-button color="warn" (click)="deleteFile(file.id)">Löschen</button>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<div *ngIf="files.length === 0" translate>Keine Dateien vorhanden</div>
