import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly themeKey = 'app-theme';
  private preferedSystemTheme: string = '';

  constructor() {
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkThemeMq.matches) {
      this.preferedSystemTheme = 'dark';
    } else {
      this.preferedSystemTheme = 'light';
    }
    darkThemeMq.addListener(this.preferedSystemThemeChanged);
  }

  getTheme(): string {
    return localStorage.getItem(this.themeKey) || 'light';
  }

  setTheme(themeName: string): void {
    document.body.classList.remove(this.getTheme() + '-theme');
    localStorage.setItem(this.themeKey, themeName);
    this.changeThemeStyles();
  }

  changeThemeStyles() {
    document.body.classList.add(this.getTheme() + '-theme');
    if (this.getTheme() === 'system') {
      document.documentElement.setAttribute(
        'data-bs-theme',
        this.preferedSystemTheme
      );
    } else {
      document.documentElement.setAttribute('data-bs-theme', this.getTheme());
    }
  }

  preferedSystemThemeChanged(e: MediaQueryListEvent): void {
    if (e.matches) {
      this.preferedSystemTheme = 'dark';
    } else {
      this.preferedSystemTheme = 'light';
    }
    this.changeThemeStyles();
  }
}
