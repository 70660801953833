import {Injectable} from '@angular/core';
import {EntityCollectionServiceBase, EntityCollectionServiceElementsFactory} from "@ngrx/data";
import {Customer} from "../models/customer";
import {first, Observable, of, switchMap, tap, throttleTime} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class CustomerEntityService extends EntityCollectionServiceBase<Customer> {

  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private httpClient: HttpClient,
  ) {
    super('Customers', serviceElementsFactory);
  }

  get(customerId?: string): Observable<Customer | undefined> {
    if (!customerId) return of(undefined);

    return this.entityMap$.pipe(
      map(entities => entities[customerId]),
      first()
    );
  }

  mergeCustomers(customerId: string, customerIds: string[]) {
    const customerIdsToMerge = customerIds.filter(it => it !== customerId);
    return this.httpClient.post(`customers/${customerId}/merge`, {customerIds: customerIdsToMerge}).pipe(
      throttleTime(300), // prevent to send another request within 300ms
      tap(() => {
        this.removeManyFromCache(customerIdsToMerge);
      }),
      switchMap(() => {
        // load the merged customer object from backend
        return this.getByKey(customerId);
      })
    );
  }
}
